import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import ClipboardCopy from '../../../components/worksafe-vic/ClipboardCopy';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing`}</h2>
    <p>{`This page includes test data and other information to make sure your integration works as planned. It can be used to trigger different flows in your integration and ensure they are handled accordingly.`}</p>
    <h4 {...{
      "id": "comcare-test-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#comcare-test-data",
        "aria-label": "comcare test data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare test data:`}</h4>
    <p>{`Claims submitted to Comcare are validated by claim number and DOB only. Any test providers can be used as long as they have the below claim number and date of birth combination. Note, the format of the claim number must include a forward slash followed by two digits. For testing purposes, any patient name can be used with the following claim numbers and DOB:`}</p>
    <p>{`For end to end testing with Comcare to submit to Comcare's testing environment claiming engine the latest test details are listed below: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1048428/2 `}<ClipboardCopy text="1048428/2" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1963-29-04`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note:If the above details do not work please contact our integration team `}<a parentName="p" {...{
          "href": "mailto:partnerships@medipass.io"
        }}>{`partnerships@medipass.io`}</a>{`.`}</p>
    </blockquote>
    <h5 {...{
      "id": "stubbing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#stubbing",
        "aria-label": "stubbing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stubbing`}</h5>
    <p>{`For Comcare injured worker insurance claims, any patient name can be used with the following claim number`}</p>
    <p>{`To receive a simulated response :`}</p>
    <ul>
      <li parentName="ul">{`7777/77 `}<ClipboardCopy text="7777/77" mdxType="ClipboardCopy" /></li>
    </ul>
    <p>{`To receive a simulated rejection:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim total amount`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Simulated response`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$0.11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entire claim is rejected`}</td>
        </tr>
      </tbody>
    </table>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "item-codes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#item-codes",
        "aria-label": "item codes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Item codes`}</h4>
    <p>{`Current Comcare item codes can be obtained via : `}<a parentName="p" {...{
        "href": "https://www.comcare.gov.au/service-providers/medical-allied-health/treatment-rates"
      }}>{`https://www.comcare.gov.au/service-providers/medical-allied-health/treatment-rates`}</a>{` `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "general-test-cases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#general-test-cases",
        "aria-label": "general test cases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General test cases`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Test no`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Test procedure`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Expected results`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim for a provider consultation. Set a webhook for status updates.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The claim is transmitted successfully and a statement is printed correctly. The correct status is reflected.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim with an invalid Comcare Claim Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interface displays an invalid item alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim where description for this item is required to be updated.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interface displays description is required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Form cannot be submitted without Description if the Description flag is true.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a Consultation and submit  with all invoices lines accepted (MBS item)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a Consultation and submit with all invoices lines accepted (Comcare item)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a Pharmacy invoice with multiple lines.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoice items will be marked as pending Review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All pharmacy claims will be placed as pending review by the pharmacy review board.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a  Consultation claim with valid claim number  and submit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is successfully submitted and approved`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a duplicate Consultation from the above test and submit it to be placed in Pending Review (Possible Duplicate Different Invoice)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in Pending Review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a Consultation that will be placed in Pending Review due to Exclusion Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in Pending Review due to Exclusion Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a Consultation and submit with all invoices lines denied (Medical & Like not accepted on claim)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Interface displays invoices lines denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit an invoice where all invoices lines denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Review marked as Rejected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit an invoice with multiple lines where one will be declined.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim rejected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If one invoice item is rejected the whole invoice is rejected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit an invoice with 5 attachments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unable to attach the 5th document as the attach document option disappears after the 4th attachment has been uploaded.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A maximum of Four documents with the limit of 25MB per document can be attached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit an invoice with one  attachment that is more than 25MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interface displays there is an error in uploading the file.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Documents can be a maximum of 25MB per document.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit an invoice with an  attachment that is less than 25MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment successfully uploaded and Claim is successfully submitted.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim that would have been placed into Pending Review is instead Denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in pending review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancel a claim that is not in the settling status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancellation Accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancel a claim that is in the settling status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancellation not accepted - Invoice is in a state where it cannot be cancelled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      